const startDay = new Date("Sep 13 2022");
const diffTime = Math.abs(new Date() - startDay);
export const legendsLevelNumber = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

export const legendsTargetIds = {
  1: "210002",
  2: "220105",
  3: "220096",
  4: "220063",
  5: "220058",
  6: "230197",
  7: "240052",
  8: "260288",
  9: "990059",
  10: "240546",
  11: "261290",
  12: "210043",
  13: "220062",
  14: "960197",
  15: "240027",
  16: "250118",
  17: "240283",
  18: "290641",
  19: "230041",
  20: "250338",
  21: "281280",
  22: "260257",
  23: "230214",
  24: "230263",
  25: "980007",
  26: "220036",
  27: "271439",
  28: "200023",
  29: "220073",
  30: "200112",
  31: "210001",
  32: "261299",
  33: "980059",
  34: "200035",
  35: "200032",
  36: "230048",
  37: "220089",
  38: "250362",
  39: "210020",
  40: "250063",
  41: "210013",
  42: "240399",
  43: "230147",
  44: "240552",
  45: "250688",
  46: "200002",
  47: "250290",
  48: "240124",
  49: "260227",
  50: "290156",
  51: "210048",
  52: "270919",
  53: "210027",
  54: "990028",
  55: "220093",
  56: "291201",
  57: "261892",
  58: "240707",
  59: "240180",
  60: "200045",
  61: "210056",
  62: "261142",
  63: "261909",
  64: "220015",
  65: "240366",
  66: "240156",
  67: "280109",
  68: "230084",
  69: "261214",
  70: "240073",
  71: "230125",
  72: "200060",
  73: "230272",
  74: "240700",
  75: "220061",
  76: "220110",
  77: "230250",
  78: "230248",
  79: "200074",
  80: "230231",
  81: "280416",
  82: "270811",
  83: "240062",
  84: "290198",
  85: "260246",
  86: "990020",
  87: "281139",
  88: "220023",
  89: "250373",
  90: "990011",
  91: "220098",
  92: "230195",
  93: "220054",
  94: "290787",
  95: "200034",
  96: "261362",
  97: "270588",
  98: "220090",
  99: "280471",
  100: "240072",
  101: "250712",
  102: "240389",
  103: "250298",
  104: "240302",
  105: "270928",
  106: "250417",
  107: "270512",
  108: "220052",
  109: "240232",
  110: "200071",
  111: "200039",
  112: "261043",
  113: "260113",
  114: "240226",
  115: "220056",
  116: "240014",
  117: "240710",
  118: "250222",
  119: "270653",
  120: "270326",
  121: "271078",
  122: "220007",
  123: "210049",
  124: "240357",
  125: "210016",
  126: "240708",
  127: "220047",
  128: "210003",
  129: "250715",
  130: "280934",
  131: "240712",
  132: "260069",
  133: "261212",
  134: "240370",
  135: "230107",
  136: "230132",
  137: "290194",
  138: "240254",
  139: "230170",
  140: "220005",
  141: "280762",
  142: "261911",
  143: "240290",
  144: "240359",
  145: "220086",
  146: "230211",
  147: "250111",
  148: "220075",
  149: "280013",
  150: "281281",
  151: "230242",
  152: "260710",
  153: "250312",
  154: "200021",
  155: "200007",
  156: "230202",
  157: "230140",
  158: "270912",
  159: "230151",
  160: "250268",
  161: "240159",
  162: "280858",
  163: "220108",
  164: "220013",
  165: "200111",
  166: "200083",
  167: "240119",
  168: "270732",
  169: "210012",
  170: "240139",
  171: "250703",
  172: "280336",
  173: "240060",
  174: "210059",
  175: "220001",
  176: "270908",
  177: "280972",
  178: "200108",
  179: "240087",
  180: "230253",
  181: "960491",
  182: "230254",
  183: "261799",
  184: "260310",
  185: "271001",
  186: "200026",
  187: "260955",
  188: "230232",
  189: "250089",
  190: "980073",
  191: "230243",
  192: "220010",
  193: "210023",
  194: "250134",
  195: "240028",
  196: "210092",
  197: "270951",
  198: "240037",
  199: "261497",
  200: "281395",
  201: "240417",
  202: "200068",
  203: "230015",
  204: "220039",
  205: "280969",
  206: "230255",
  207: "240419",
  208: "210018",
  209: "280863",
  210: "250340",
  211: "250548",
  212: "240130",
  213: "240336",
  214: "210099",
  215: "270742",
  216: "200079",
  217: "260750",
  218: "295467",
  219: "271015",
  220: "291492",
  221: "290117",
  222: "294877",
  223: "293535",
  224: "281080",
  225: "291545",
  226: "270935",
  227: "280804",
  228: "290826",
  229: "290801",
  230: "295584",
  231: "290188",
  232: "291783",
  233: "270896",
  234: "992016",
  235: "270963",
  236: "290832",
  237: "290847",
  238: "281373",
  239: "295265",
  240: "293222",
  241: "280104",
  242: "291548",
  243: "290778",
  244: "291748",
  245: "293884",
  246: "291867",
  247: "271129",
  248: "291526",
  249: "280763",
  250: "291771",
  251: "280317",
  252: "290311",
  253: "261209",
  254: "293193",
  255: "294318",
  256: "291533",
  257: "291570",
  258: "230197",
  259: "280990",
  260: "261396",
  261: "960197",
  262: "260930",
  263: "291773",
  264: "261323",
  265: "250118",
  266: "290641",
  267: "230214",
  268: "220096",
  269: "220105",
  270: "250105",
  271: "230048",
  272: "220058",
  273: "250362",
  274: "220036",
  275: "261299",
  276: "240546",
  277: "210013",
  278: "230263",
  279: "230041",
  280: "210043",
  281: "280078",
  282: "240052",
  283: "261384",
  284: "280965",
  285: "200112",
  286: "220073",
  287: "210020",
  288: "290823",
  289: "281007",
  290: "250338",
  291: "280819",
  292: "271439",
  293: "261290",
  294: "260257",
  295: "260382",
  296: "280737",
  297: "220089",
  298: "270861",
  299: "250321",
  300: "250063",
  301: "280944",
  302: "250267",
  303: "210001",
  304: "240406",
  305: "280824",
  306: "240027",
  307: "980059",
  308: "281280",
  309: "200023",
  310: "220062",
  311: "270325",
  312: "290623",
  313: "200032",
  314: "990059",
  315: "260288",
  316: "240283",
  317: "280012",
  318: "210002",
  319: "200035",
  320: "220063",
  321: "270938",
  322: "980007",
  323: "220093",
  324: "250365",
  325: "210027",
  326: "271072",
  327: "250290",
  328: "291313",
  329: "290527",
  330: "261892",
  331: "230231",
  332: "230248",
  333: "240156",
  334: "290799",
  335: "281085",
  336: "220015",
  337: "200060",
  338: "280744",
  339: "281065",
  340: "280711",
  341: "291357",
  342: "210056",
  343: "210048",
  344: "220110",
  345: "290528",
  346: "200074",
  347: "250688",
  348: "240552",
  349: "270146",
  350: "280921",
  351: "292511",
  352: "240073",
  353: "230084",
  354: "240124",
  355: "240399",
  356: "290156",
  357: "261909",
  358: "270919",
  359: "291201",
  360: "292145",
  361: "291351",
  362: "290550",
  363: "260227",
  364: "240707",
  365: "290629",
  366: "261142",
  367: "240700",
  368: "240366",
  369: "990028",
  370: "290160",
  371: "200002",
  372: "271045",
  373: "230147",
  374: "220061",
  375: "261510",
  376: "230250",
  377: "240180",
  378: "270917",
  379: "291570",
  380: "281281",
  381: "230242",
  382: "280819",
  383: "291856",
  384: "280416",
  385: "281078",
  386: "250417",
  387: "220023",
  388: "250712",
  389: "293871",
  390: "261224",
  391: "296735",
  392: "200039",
  393: "280109",
  394: "281124",
  395: "293845",
  396: "294101",
  397: "293738",
  398: "990740",
  399: "294859",
  400: "220098",
  401: "240062",
  402: "295461",
  403: "290085",
  404: "281139",
  405: "291720",
  406: "297373",
  407: "291962",
  408: "270588",
  409: "200109",
  410: "298272",
  411: "293713",
  412: "296347",
  413: "260246",
  414: "294125",
  415: "220052",
  416: "290307",
  417: "240232",
  418: "294518",
  419: "260113",
  420: "240389",
  421: "293846",
  422: "270928",
  423: "296254",
  424: "250395",
  425: "290838",
  426: "230272",
  427: "991930",
  428: "270811",
  429: "250373",
  430: "295518",
  431: "296269",
  432: "296733",
  433: "220090",
  434: "298539",
  435: "291969",
  436: "230125",
  437: "294592",
  438: "280506",
  439: "261043",
  440: "291776",
  441: "294613",
  442: "280471",
  443: "994389",
  444: "294674",
  445: "996701",
  446: "294305",
  447: "291819",
  448: "261214",
  449: "293957",
  450: "200034",
  451: "200071",
  452: "291978",
  453: "291790",
  454: "296291",
  455: "290198",
  456: "298279",
  457: "293813",
  458: "291509",
  459: "990704",
  460: "291975",
  461: "294557",
  462: "291902",
  463: "292128",
  464: "293716",
  465: "290627",
  466: "220056",
  467: "990011",
  468: "250298",
  469: "992010",
  470: "296351",
  471: "293801",
  472: "996765",
  473: "240072",
  474: "996059",
  475: "295067",
  476: "297504",
  477: "990609",
  478: "294596",
  479: "290787",
  480: "990020",
  481: "295342",
  482: "230195",
  483: "295136",
  484: "298210",
  485: "270512",
  486: "294643",
  487: "295344",
  488: "261362",
  489: "200045",
  490: "240226",
  491: "296355",
  492: "293883",
  493: "240302",
  494: "220054",
  495: "210059",
  496: "992049",
  497: "280858",
  498: "240336",
  499: "230243",
  500: "230253",
  501: "1000981",
  502: "220047",
  503: "250111",
  504: "210012",
  505: "200111",
  506: "280013",
  507: "230107",
  508: "993905",
  509: "220075",
  510: "240139",
  511: "960491",
  512: "280336",
  513: "993107",
  514: "230232",
  515: "220108",
  516: "240370",
  517: "993832",
  518: "270653",
  519: "230151",
  520: "280104",
  521: "271015",
  522: "993817",
  523: "261799",
  524: "295467",
  525: "250703",
  526: "992016",
  527: "271078",
  528: "240159",
  529: "298268",
  530: "280972",
  531: "200079",
  532: "200021",
  533: "291548",
  534: "260750",
  535: "250715",
  536: "261911",
  537: "280863",
  538: "270896",
  539: "200026",
  540: "230170",
  541: "240290",
  542: "294307",
  543: "296420",
  544: "240357",
  545: "210018",
  546: "261497",
  547: "295265",
  548: "290188",
  549: "1000978",
  550: "240087",
  551: "993953",
  552: "230255",
  553: "250312",
  554: "280934",
  555: "270742",
  556: "240708",
  557: "200108",
  558: "210003",
  559: "240119",
  560: "240014",
  561: "293222",
  562: "210092",
  563: "980073",
  564: "260069",
  565: "240028",
  566: "260382",
  567: "290832",
  568: "290527",
  569: "291867",
  570: "270912",
  571: "280762",
  572: "291800",
  573: "220086",
  574: "281395",
  575: "240712",
  576: "280965",
  577: "250267",
  578: "296359",
  579: "280078",
  580: "270935",
  581: "293193",
  582: "291545",
  583: "270732",
  584: "220010",
  585: "230015",
  586: "260710",
  587: "290778",
  588: "293884",
  589: "294877",
  590: "250365",
  591: "250268",
  592: "261212",
  593: "250089",
  594: "260310",
  595: "230132",
  596: "240406",
  597: "270963",
  598: "291492",
  599: "295584",
  600: "281080",
  601: "291773",
  602: "298264",
  603: "290799",
  604: "270938",
  605: "280012",
  606: "200083",
  607: "994185",
  608: "280990",
  609: "260955",
  610: "250548",
  611: "220001",
  612: "290823",
  613: "271129",
  614: "290623",
  615: "210023",
  616: "240710",
  617: "280969",
  618: "210016",
  619: "240130",
  620: "280763",
  621: "261384",
  622: "290757",
  623: "240060",
  624: "250222",
  625: "240359",
  626: "230202",
  627: "281085",
  628: "290826",
  629: "250340",
  630: "271001",
  631: "281007",
  632: "294266",
  633: "280804",
  634: "291783",
  635: "296205",
  636: "220007",
  637: "290117",
  638: "270861",
  639: "294036",
  640: "291748",
  641: "240037",
  642: "280317",
  643: "250134",
  644: "280824",
  645: "270908",
  646: "296296",
  647: "270951",
  648: "240417",
  649: "293535",
  650: "200068",
  651: "291526",
  652: "261209",
  653: "298281",
  654: "230140",
  655: "230211",
  656: "270325",
  657: "291771",
  658: "220005",
  659: "240254",
  660: "291533",
  661: "280944",
  662: "291753",
  663: "260930",
  664: "294318",
  665: "271072",
  666: "210049",
  667: "291313",
  668: "270326",
  669: "261396",
  670: "200007",
  671: "210099",
  672: "291357",
  673: "290194",
  674: "280744",
  675: "220039",
  676: "250105",
  677: "281065",
  678: "230254",
  679: "281373",
  680: "280711",
  681: "240419",
  682: "297899",
  683: "261323",
  684: "290847",
  685: "290311",
  686: "298288",
  687: "290801",
  688: "220013",
  689: "280737",
  690: "250321",
  691: "240062",
  692: "200039",
  693: "250290",
  694: "992049",
  695: "980007",
  696: "290629",
  697: "210012",
  698: "293845",
  699: "290838",
  700: "270928",
  701: "230195",
  702: "230242",
  703: "280819",
  704: "280921",
  705: "230253",
  706: "230197",
  707: "250338",
  708: "297373",
  709: "240027",
  710: "230147",
  711: "280013",
  712: "280416",
  713: "250111",
  714: "291720",
  715: "290528",
  716: "220054",
  717: "220015",
  718: "980059",
  719: "990020",
  720: "200060",
  721: "990059",
  722: "993905",
  723: "293801",
  724: "261909",
  725: "996765",
  726: "270917",
  727: "270919",
  728: "250118",
  729: "291790",
  730: "291978",
  731: "296347",
  732: "294125",
  733: "293716",
  734: "260246",
  735: "280858",
  736: "294592",
  737: "296254",
  738: "295067",
  739: "296733",
  740: "296269",
  741: "294101",
  742: "220090",
  743: "210056",
  744: "230263",
  745: "220110",
  746: "291776",
  747: "210002",
  748: "230041",
  749: "290641",
  750: "1000981",
  751: "294596",
  752: "240366",
  753: "200034",
  754: "200111",
  755: "220052",
  756: "280109",
  757: "270811",
  758: "270146",
  759: "220089",
  760: "261362",
  761: "210001",
  762: "290787",
  763: "292511",
  764: "240226",
  765: "220062",
  766: "210027",
  767: "200032",
  768: "220093",
  769: "200023",
  770: "230214",
  771: "250417",
  772: "240707",
  773: "250362",
  774: "996701",
  775: "261043",
  776: "250395",
  777: "291962",
  778: "292128",
  779: "220056",
  780: "261224",
  781: "290156",
  782: "240389",
  783: "295461",
  784: "260257",
  785: "281281",
  786: "230250",
  787: "220023",
  788: "990011",
  789: "261142",
  790: "260227",
  791: "240180",
  792: "240156",
  793: "295342",
  794: "220061",
  795: "200045",
  796: "990028",
  797: "200035",
  798: "293713",
  799: "240139",
  800: "295136",
  801: "240073",
  802: "210059",
  803: "261214",
  804: "271439",
  805: "270512",
  806: "293957",
  807: "230084",
  808: "260288",
  809: "991930",
  810: "210020",
  811: "290307",
  812: "291856",
  813: "280506",
  814: "260113",
  815: "281280",
  816: "240546",
  817: "295344",
  818: "240052",
  819: "294674",
  820: "293846",
  821: "200071",
  822: "298272",
  823: "240072",
  824: "292145",
  825: "291351",
  826: "290550",
  827: "290160",
  828: "271045",
  829: "261510",
  830: "281078",
  831: "293871",
  832: "296735",
  833: "281124",
  834: "293738",
  835: "990740",
  836: "294859",
  837: "290085",
  838: "294518",
  839: "295518",
  840: "298539",
  841: "291969",
  842: "294613",
  843: "994389",
  844: "294305",
  845: "291819",
  846: "296291",
  847: "298279",
  848: "293813",
  849: "291509",
  850: "990704",
  851: "291975",
  852: "294557",
  853: "291902",
  854: "290627",
  855: "992010",
  856: "296351",
  857: "996059",
  858: "297504",
  859: "990609",
  860: "298210",
  861: "294643",
  862: "296355",
  863: "293883",
  864: "294643",
  865: "296355",
  866: "293883",
  867: "291867",
  868: "270912",
  869: "280762",
  870: "291800",
  871: "220086",
  872: "281395",
  873: "240712",
  874: "280965",
  875: "250267",
  876: "296359",
  877: "280078",
  878: "270935",
  879: "293193",
  880: "291545",
  881: "270732",
  882: "220010",
  883: "230015",
  884: "260710",
  885: "290778",
  886: "293884",
  887: "294877",
  888: "250365",
  889: "250268",
  890: "261212",
  891: "250089",
  892: "260310",
  893: "230132",
  894: "240406",
  895: "270963",
  896: "291492",
  897: "295584",
  898: "281080",
  899: "291773",
  900: "298264",
  901: "290799",
  902: "295461",
  903: "290085",
  904: "281139",
  905: "291720",
  906: "297373",
  907: "291962",
  908: "270588",
  909: "298272",
  910: "293713",
  911: "296347",
  912: "291771",
  913: "220005",
  914: "240254",
  915: "291533",
  916: "280944",
  917: "291753",
  918: "260930",
  919: "294318",
  920: "271072",
  921: "210049",
  922: "291313",
  923: "270326",
  924: "261396",
  925: "200007",
  926: "210099",
  927: "291357",
  928: "290194",
  929: "280744",
  930: "220039",
  931: "250105",
  932: "281065",
  933: "230254",
  934: "281373",
};
